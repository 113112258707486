import { useState } from "react";
import CurrencyInput from "react-currency-input-field";
import Logo from "../../assets/imgs/logo.png";
import Firebase from "../../utils/Firebase";
// import { createClient } from "@supabase/supabase-js";
// import { Auth } from "@supabase/auth-ui-react";
// import { ThemeSupa } from "@supabase/auth-ui-shared";

// // Needed For Stripe
var TerminalIDGlobal = "tmr_FffJXgfr87sUzZ";
var APIKeyGlobal =
  "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";

// // URL Encode Function
function URLEncodeRequest(data) {
  var formBodyEncoded = [];
  for (var prop in data) {
    var encodedKey = encodeURIComponent(prop);
    var encodedValue = encodeURIComponent(data[prop]);
    formBodyEncoded.push(encodedKey + "=" + encodedValue);
  }
  formBodyEncoded = formBodyEncoded.join("&");
  return formBodyEncoded;
}

// // Create Payment and Hand off to Reader
function CreatePaymentIntent(value, descriptionInput, CustomerEmail) {
  let rawValue = value * 100;
  rawValue = rawValue.toFixed(0);
  // ADD Percentage
  const data = {
    amount: rawValue,
    description: descriptionInput,
    currency: "usd",
    "payment_method_types[]": ["card_present"],
  };
  if (CustomerEmail != "") {
    data["receipt_email"] = CustomerEmail;
  }
  let formBodyEncoded = URLEncodeRequest(data);
  fetch("https://api.stripe.com/v1/payment_intents", {
    method: "POST",
    headers: {
      "Content-Type": "application/json",
      Authorization: "Bearer " + APIKeyGlobal,
      "Content-Type": "application/x-www-form-urlencoded;charset=UTF-8",
    },
    body: formBodyEncoded,
  })
    .then((response) => response.json())
    .then((data) => {
      console.log("Success:", data);
      if (data.error) {
        alert(data.error.message);
      } else {
        alert("Payment Created Successfully!");
      }
      //alert(data.id)
      // Create Data
      const dataForHandingOffIntent = {
        payment_intent: data.id,
      };
      let PaymentIDEncoded = URLEncodeRequest(dataForHandingOffIntent);
      // Hand Off Payment Intent to a reader

      let HandOffURL =
        "https://api.stripe.com/v1/terminal/readers/" +
        TerminalIDGlobal +
        "/process_payment_intent";
      fetch(HandOffURL, {
        method: "POST",
        headers: {
          "Content-Type": "application/json",
          Authorization: "Bearer " + APIKeyGlobal,
          "Content-Type": "application/x-www-form-urlencoded;charset=UTF-8",
        },
        body: PaymentIDEncoded,
      })
        .then((res) => {
          res.json();
        })
        .then((readerData) => {
          //Alert.alert(readerData);
          console.log("Payment Handed Off Successfully", readerData);
        })
        .catch((er) => {
          alert(er.error.message);
          console.error("Error From Reader", er);
        });
    })
    .catch((error) => {
      console.error("Error:", error);
    });
}

// // Clear Reader
function ClearReaderScreen() {
  var requestURL = `https://api.stripe.com/v1/terminal/readers/${TerminalIDGlobal}/cancel_action`;
  fetch(requestURL, {
    method: "POST",
    headers: {
      "Content-Type": "application/json",
      Authorization: "Bearer " + APIKeyGlobal,
      "Content-Type": "application/x-www-form-urlencoded;charset=UTF-8",
    },
  })
    .then((res) => {
      res.json();
    })
    .then((readerData) => {
      console.log("Payment Handed Off Successfully", readerData);
    })
    .catch((er) => {
      console.error("Error From Reader", er);
    });
}
function Home() {
  // Supabase Session
  //const [APIKeyInput, setAPIKeyInput] = useState("");
  //const [TerminalIDInput, setTerminalIDInput] = useState("");

  const [amount, setAmount] = useState(0);
  const [Description, setDescription] = useState("");
  const [Email, setEmail] = useState("");

  async function logout() {
    await Firebase.signOut().then((res) => {console.log(res)})
  }
  //checkTerminalID();
  //checkAPIKey();
  //
  return (
    <>
      <div className="min-h-screen flex flex-col">
        <nav className="bg-gray-800 text-white">
          <div className="max-w-7xl mx-auto px-4 sm:px-6 lg:px-8">
            <div className="flex items-center justify-between h-16">
              <div className="flex items-center">
                <a href="/" className="text-xl font-bold text-white">
                  <img src={Logo} alt="logo" className="w-32" />
                </a>
              </div>
              <div className="md:flex space-x-4">
                <button
                  className="hover:bg-gray-700 px-3 py-2 rounded-md text-sm"
                  onClick={logout}
                >
                  Log out
                </button>
              </div>
            </div>
          </div>
        </nav>

        <div className="flex-1 bg-gray-100 flex items-center justify-center">
          <div className="bg-white p-8 rounded-lg shadow-md w-full max-w-md">
            <h2 className="text-2xl font-bold text-gray-800 text-center mb-6">
              IDEAL Shower Doors
            </h2>
            <div>
              {/* Input Fields */}
              <div className="mb-4">
                <label
                  htmlFor="amount"
                  className="block text-sm font-medium text-gray-700"
                >
                  Amount $
                </label>
                <CurrencyInput
                  id="amount"
                  className="mt-1 block w-full px-4 py-2 border border-gray-300 rounded-md shadow-sm focus:ring-blue-500 focus:border-blue-500 sm:text-sm"
                  prefix="$"
                  value={amount}
                  decimalsLimit={2}
                  onValueChange={(value, name) => setAmount(value)}
                />
              </div>

              <div className="mb-4">
                <label
                  htmlFor="email"
                  className="block text-sm font-medium text-gray-700"
                >
                  Customer Email
                </label>
                <input
                  id="email"
                  className="mt-1 block w-full px-4 py-2 border border-gray-300 rounded-md shadow-sm focus:ring-blue-500 focus:border-blue-500 sm:text-sm"
                  placeholder="example@gmail.com"
                  type="email"
                  value={Email}
                  onChange={(EmailInputChageEvent) =>
                    setEmail(EmailInputChageEvent.target.value)
                  }
                />
              </div>

              <div className="mb-6">
                <label
                  htmlFor="description"
                  className="block text-sm font-medium text-gray-700"
                >
                  Description
                </label>
                <textarea
                  id="description"
                  className="mt-1 block w-full px-4 py-2 border border-gray-300 rounded-md shadow-sm focus:ring-blue-500 focus:border-blue-500 sm:text-sm"
                  placeholder="description for payment"
                  value={Description}
                  onChange={(DescriptionInputChangeEvent) =>
                    setDescription(DescriptionInputChangeEvent.target.value)
                  }
                />
              </div>

              {/* Buttons */}
              <div className="flex items-center justify-between">
                <button
                  className="w-full py-2 px-4 bg-blue-600 text-white font-semibold rounded-md shadow-sm hover:bg-blue-700 focus:ring-2 focus:ring-blue-500 focus:ring-offset-2 transition duration-200"
                  onClick={() => {
                    CreatePaymentIntent(amount, Description, Email);
                  }}
                >
                  Take Payment
                </button>
              </div>

              <div className="mt-4">
                <button
                  className="w-full py-2 px-4 bg-gray-600 text-white font-semibold rounded-md shadow-sm hover:bg-gray-700 focus:ring-2 focus:ring-gray-500 focus:ring-offset-2 transition duration-200"
                  onClick={() => {
                    ClearReaderScreen();
                  }}
                >
                  Clear Reader
                </button>
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  );
}
export default Home;
