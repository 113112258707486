import React from "react";
import "./style.css";
import 'react-toastify/dist/ReactToastify.css';
import Router from "./routes/Router";
import { ToastContainer } from "react-toastify";

function App() {
  return (
    <div className="App">
      <ToastContainer />
      <Router />
    </div>
  );
}

export default App;
