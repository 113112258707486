import { BrowserRouter, Route, Routes } from "react-router-dom";
import SignIn from "../views/SignIn";
import Home from "../views/Home";
import ProtectedRoute from "./ProtectedRoute";
export default function Router() {
  return (
    <BrowserRouter>
      <Routes>
        <Route path="/" element={<ProtectedRoute><Home /></ProtectedRoute>} />
        <Route path="/sign-in" element={<SignIn />} />
      </Routes>
    </BrowserRouter>
  );
}
