import React from "react";
import { Navigate } from "react-router-dom";
import { useAuthState } from "react-firebase-hooks/auth";
import { auth } from "../utils/Firebase";

const ProtectedRoute = ({ children }) => {
  const [user, loading] = useAuthState(auth);
  if (loading) {
    // While Firebase is restoring the session, show a loading spinner or placeholder
    return <div></div>;
  }

  return user ? children : <Navigate to="/sign-in" />;
};

export default ProtectedRoute;
